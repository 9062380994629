import React from 'react'
import {Link} from 'gatsby'
import logo from '../pics/barcelona_code_school_logo.png'
import ham from './Header/hamburgerMenu.svg'

class Navbar extends React.Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
    };

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);
  }



  showMenu(event) {
    event.preventDefault();

    this.setState({
      showMenu: true
    });

    document.addEventListener("click", this.closeMenu);
  }

  closeMenu(event) {
    // close dropdown if clicked INSIDE or OUTSIDE menu, could get rid of IF alltogether
    if (this.dropdownMenu.contains(event.target) || !this.dropdownMenu.contains(event.target)) {
      this.setState({
        showMenu: false
      });

      document.removeEventListener("click", this.closeMenu);
    }
  }

  render() {
    return (
      <div className='nav navbarHam'>

      <div className='dropDownInner' 
      style={{
        position: 'relative',

      }}>
      <Link className="hamburgerMenu"
      style={{
        color: 'white',
        textDecoration: 'none',
        marginRight: '1em',
      }}
      to="#"
      onClick={this.showMenu}>
      <img src={ham} />
      </Link>

      {this.state.showMenu ? (
        <div
        style={{
          // figure out best centering for this div
          textAlign: 'left',
          width: '100vw',
          height: '100vh',
          left: '-1em',
          position: 'absolute',
          background: 'rgb(0,0,0,80%)',
          color: 'white',
          padding: '10px',
          overflow: 'scroll',
          paddingBottom:'20vh'
        }}
        ref={element => {
          this.dropdownMenu = element;
        }}
        >

        <p className='navSeparator'><strong>Bootcamps ↓</strong></p>

        <Link className="HamDD" 
        to="/barcelona-code-school/javascript-full-stack-bootcamp/"
        >
        JavaScript Full-Stack <strong>in-person</strong> Bootcamp
        </Link>

        <Link className="HamDD"
        to="/game-development-bootcamp/"
        >
        Game Development in-person Bootcamp
        </Link>

        <Link className="HamDD" 
        to="/ux-design-bootcamp-in-barcelona-code-school/"
        >
        UX/UI Design <strong>in-person</strong> Bootcamp
        </Link>

        <Link className="HamDD" 
        to="/barcelona-code-school/javascript-full-stack-online-bootcamp/"
        >
        JavaScript Full-Stack <strong>Online Mentored</strong> Bootcamp
        </Link>

        <Link className="HamDD" 
        to="/ux-design-online-mentored-bootcamp/"
        >
        UX/UI Design <strong>Online Mentored</strong> Bootcamp
        </Link>

        <Link className="HamDD" 
        to="/machine-learning-and-ai-engineering-bootcamp/"
        >
        Deep Learning and AI Engineering <strong>Online Mentored</strong> Bootcamp
        </Link>


{/*        <Link className="HamDD" 
        to="/advanced-web-develoment-bootcamp/"
        >
        Advanced Web Development <strong>Online Mentored</strong> Bootcamp
        </Link>*/}



{/*      <Link className="HamDD" 
      to="/javascript-full-time-online-bootcamp/"
      >
      JavaScript Full-Stack Online Live Bootcamp
    </Link>*/}





        <p className='navSeparator'><strong>In-person short courses ↓</strong></p>


        <Link className="HamDD" 
        to="/cyber-security-for-web-developers/"
        >
        Cyber Security For Web Developers
        </Link>

        <Link className="HamDD" 
        to="/responsive-web-design-with-css3/"
        >
        Responsive web design / CSS3
        </Link>

        <Link className="HamDD" 
        to="/programming-with-javascript/"
        >
        Programming with JavaScript
        </Link>

        <Link className="HamDD" 
        to="/reactjs-crash-course/"
        >
        React.js Crash Course
        </Link>

        <Link className="HamDD" 
        to="/backend-development-with-node-express-mongodb/"
        >
        Back-end development with Express
        </Link>

        {/* <Link className="HamDD" 
        to="/bcn-coding-kids/"
        >
        Coding for kids
        </Link> */}

        <p className='navSeparator'><strong>Online short courses ↓</strong></p>

        <Link className="HamDD" 
        to="/programming-with-python/"
        >
        Programming with Python
        </Link>


        <Link className="HamDD" 
        to="/online-short-courses/programming-with-javascript-online-course/"
        >
        Programming with JavaScript Online Course
        </Link>

        <Link className="HamDD" 
        to="/online-short-courses/react-crash-course-online/"
        >
        React.js Online Crash Course
        </Link>

        <Link className="HamDD" 
        to="/online-short-courses/backend-development-with-node-express-mongodb-online/"
        >
        Back-end development with Express Online course
        </Link>

        <Link className="HamDD" 
        to="/online-short-courses/responsive-web-design-with-css3-online/"
        >
        Responsive web design with HTML5/CSS3 
        </Link>

   {/* <p className='navSeparator'><strong>Kids and corporate ↓</strong></p>


    <Link className="HamDD" 
    to="/bcn-coding-kids/bcs-young-coders-summer-camp/"
    >
    BCS Young Coders
    </Link>

    <Link className="HamDD" 
    to="/corporate-training/"
    >
    Corporate training
    </Link>*/}

        <p className='navSeparator'><strong>For companies ↓</strong></p>

        <Link className="HamDD" 
        to="/job-offer/"
        >
        Hire graduates
        </Link>

        <Link className="HamDD" 
        to="/corporate-training/"
        >
        Corporate training
        </Link>

        <p className='navSeparator'><strong>School ↓</strong></p>


        <Link className="HamDD" 
        to="/contacts/"
        >
        Contact us
        </Link>

        <Link className="HamDD" 
        to="/about-us/school/"
        >
        About us
        </Link>

        <Link className="HamDD" 
        to="/about-us/testimonials/"
        >
        Testimonials
        </Link>

        <Link className="HamDD"
        to="/blog/"
        >
        Blog
        </Link>

        <Link className="HamDD"
        to="/lab/"
        style={{marginBottom:'3em',}}
        >
        BCS Lab
        </Link>

        </div>
        ) : null}
</div>





</div>
);
}
}

export default Navbar