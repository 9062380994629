import React from "react";
import { render } from "react-dom";
import {Link} from 'gatsby'
import logo from '../pics/barcelona_code_school_logo.png'


class Navbar extends React.Component {
  constructor() {
    super();

    this.state = {
      showMenu: false,
      showMenuBootcamps: false,
      showMenuSchool: false
    };

    this.showMenuBootcamps = this.showMenuBootcamps.bind(this);
    this.closeMenuBootcamps = this.closeMenuBootcamps.bind(this);

    this.showMenuOnlineBootcamps = this.showMenuOnlineBootcamps.bind(this);
    this.closeMenuOnlineBootcamps = this.closeMenuOnlineBootcamps.bind(this);

    this.showMenu = this.showMenu.bind(this);
    this.closeMenu = this.closeMenu.bind(this);

    this.showMenuSchool = this.showMenuSchool.bind(this);
    this.closeMenuSchool = this.closeMenuSchool.bind(this);

    this.showMenuForCompanies = this.showMenuForCompanies.bind(this);
    this.closeMenuForCompanies = this.closeMenuForCompanies.bind(this);

  }

  showMenuBootcamps(event) {
    event.preventDefault();
    this.setState({
      showMenuBootcamps: true
    });

    document.addEventListener("click", this.closeMenuBootcamps);
  }

  closeMenuBootcamps(event) {

    // close dropdown if clicked INSIDE or OUTSIDE menu, could get rid of IF alltogether
    if (this.dropdownMenuBootcamps.contains(event.target) || !this.dropdownMenuBootcamps.contains(event.target)) {
      this.setState({
        showMenuBootcamps: false
      });

      document.removeEventListener("click", this.closeMenuBootcamps);
    }
  }

  showMenuOnlineBootcamps(event) {
    event.preventDefault();
    this.setState({
      showMenuOnlineBootcamps: true
    });

    document.addEventListener("click", this.closeMenuOnlineBootcamps);
  }

  closeMenuOnlineBootcamps(event) {

    // close dropdown if clicked INSIDE or OUTSIDE menu, could get rid of IF alltogether
    if (this.dropdownMenuOnlineBootcamps.contains(event.target) || !this.dropdownMenuOnlineBootcamps.contains(event.target)) {
      this.setState({
        showMenuOnlineBootcamps: false
      });

      document.removeEventListener("click", this.closeMenuOnlineBootcamps);
    }
  }

  showMenuSchool(event) {
    event.preventDefault();
    this.setState({
      showMenuSchool: true
    });

    document.addEventListener("click", this.closeMenuSchool);
  }

  closeMenuSchool(event) {
    // close dropdown if clicked INSIDE or OUTSIDE menu, could get rid of IF alltogether
    if (this.dropdownMenuSchool.contains(event.target) || !this.dropdownMenuSchool.contains(event.target)) {
      this.setState({
        showMenuSchool: false
      });

      document.removeEventListener("click", this.closeMenuSchool);
    }
  }

  showMenuForCompanies(event) {
    event.preventDefault();
    this.setState({
      showMenuForCompanies: true
    });

    document.addEventListener("click", this.closeMenuForCompanies);
  }

  closeMenuForCompanies(event) {
    // close dropdown if clicked INSIDE or OUTSIDE menu, could get rid of IF alltogether
    if (this.dropdownMenuForCompanies.contains(event.target) || !this.dropdownMenuForCompanies.contains(event.target)) {
      this.setState({
        showMenuForCompanies: false
      });

      document.removeEventListener("click", this.closeMenuForCompanies);
    }
  }



  showMenu(event) {
    event.preventDefault();

    this.setState({
      showMenu: true
    });

    document.addEventListener("click", this.closeMenu);
  }

  closeMenu(event) {

    // close dropdown if clicked INSIDE or OUTSIDE menu, could get rid of IF alltogether
    if (this.dropdownMenu.contains(event.target) || !this.dropdownMenu.contains(event.target)) {
      this.setState({
        showMenu: false
      });

      document.removeEventListener("click", this.closeMenu);
    }
  }

  render() {
    return (
      <div className='nav navbarDesktop'>
{/*      <Link 
      to="/"
      style={{
        maxHeight: '100%'
      }}
      >
      <img style={{
        height: '3em',
      }}src={logo} alt="barcelona code school logo" />
    </Link>*/}

      <div style={{
        position: 'relative',

      }}>
      <Link style={{
        textDecoration: 'none',
        marginRight: '1em',
        whiteSpace: 'nowrap'
      }}
      to="#"
      onClick={this.showMenuBootcamps}>In-person Bootcamps ↓</Link>
      {this.state.showMenuBootcamps ? (
        <div
        style={{
          // figure out best centering for this div
          width: '16em',
          left: '0',
          top: '1.5em',
          position: 'absolute',
          background: 'rgb(0,0,0,80%)',
          padding: '1em',
          overflow: 'scroll',
          // height: '100vh',
        }}
        ref={element => {
          this.dropdownMenuBootcamps = element;
        }}
        >


        <Link 
        to="/barcelona-code-school/javascript-full-stack-bootcamp/"
        style={{
          width: '100%',
          display: 'block',
          textDecoration: 'none',
          margin: '1em auto',
          textAlign: 'center'
        }}
        >
        JavaScript Full-Stack <strong>in-person</strong> Bootcamp
        </Link>

        <Link 
        to="/game-development-bootcamp/"
        style={{
          width: '100%',
          display: 'block',
          textDecoration: 'none',
          margin: '1em auto',
          textAlign: 'center'
        }}
        >
        Game Development <strong>in-person</strong> Bootcamp
        </Link>

        <Link 
        to="/ux-design-bootcamp-in-barcelona-code-school/"
        style={{
          width: '100%',
          display: 'block',
          textDecoration: 'none',
          margin: '1em auto',
          textAlign: 'center',

        }}
        >
        UX/UI Design <strong>in-person</strong> Bootcamp
        </Link>







        </div>
        ) : null}
      </div>




      <div style={{
        position: 'relative',

      }}>
      <Link style={{
        textDecoration: 'none',
        marginRight: '1em',
        whiteSpace: 'nowrap'
      }}
      to="#"
      onClick={this.showMenuOnlineBootcamps}>Online Bootcamps ↓</Link>
      {this.state.showMenuOnlineBootcamps ? (
        <div
        style={{
          // figure out best centering for this div
          width: '16em',
          left: '-4em',
          top: '1.5em',
          position: 'absolute',
          background: 'rgb(0,0,0,80%)',
          padding: '1em',
          overflow: 'scroll',
          // height: '100vh',
        }}
        ref={element => {
          this.dropdownMenuOnlineBootcamps = element;
        }}
        >


        <Link 
        to="/ux-design-online-mentored-bootcamp/"
        style={{
          width: '100%',
          display: 'block',
          textDecoration: 'none',
          margin: '1em auto',
          textAlign: 'center',

        }}
        >
        UX/UI Design <strong>Online Mentored</strong> Bootcamp
        </Link>

        

        <Link 
        to="/barcelona-code-school/javascript-full-stack-online-bootcamp/"
        style={{
          width: '100%',
          display: 'block',
          textDecoration: 'none',
          margin: '1em auto',
          textAlign: 'center',

        }}
        >
        JavaScript Full-Stack <strong>Online Mentored</strong> Bootcamp
        </Link>


        <Link 
        to="/machine-learning-and-ai-engineering-bootcamp/"
        style={{
          width: '100%',
          display: 'block',
          textDecoration: 'none',
          margin: '1em auto',
          textAlign: 'center',

        }}
        >
        Deep Learning and AI Engineering <strong>Online Mentored</strong> Bootcamp
        </Link>

{/*        <Link 
        to="/advanced-web-develoment-bootcamp/"
        style={{
          width: '100%',
          display: 'block',
          textDecoration: 'none',
          margin: '1em auto',
          textAlign: 'center',

        }}
        >
        Advanced Web Development <strong>Online Mentored</strong> Bootcamp
        </Link>*/}


        </div>
        ) : null}
      </div>



      <div style={{
        position: 'relative',

      }}>
      <Link style={{
        textDecoration: 'none',
        marginRight: '1em',
        whiteSpace: 'nowrap'
      }}
      to="#"
      onClick={this.showMenu}>Short Courses ↓</Link>
      {this.state.showMenu ? (
        <div
        style={{
          // figure out best centering for this div
          width: '16em',
          left: '-50%',
          top: '1.5em',
          position: 'absolute',
          background: 'rgb(0,0,0,80%)',
          padding: '1em',
          overflow: 'scroll',
          // height: '100vh',
        }}
        ref={element => {
          this.dropdownMenu = element;
        }}
        >
        {/*<Link 
        to="/online-marketing-social-media-seo-digital-marketing/"
        style={{
          width: '100%',
          display: 'block',
          color: '#ddd',
          textDecoration: 'none',
          margin: '1em auto',
          textAlign: 'center'
        }}
        >
        <strong>Online growth for your business</strong>
      </Link>*/}


        <p className='navSeparator'><strong>In-person short courses&nbsp;↓</strong></p>



        <Link 
        to="/cyber-security-for-web-developers/"
        style={{
          width: '100%',
          display: 'block',
          textDecoration: 'none',
          margin: '1em auto',
          textAlign: 'center',
        }}
        >
        Cyber Security For Web Developers
        </Link>

        <Link 
        to="/responsive-web-design-with-css3/"
        style={{
          width: '100%',
          display: 'block',
          textDecoration: 'none',
          margin: '1em auto',
          textAlign: 'center',

        }}
        >
        Responsive web design / CSS3 
        </Link>

        <Link 
        to="/programming-with-javascript/"
        style={{
          width: '100%',
          display: 'block',
          textDecoration: 'none',
          margin: '1em auto',
          textAlign: 'center',

        }}
        >
        Programming with JavaScript
        </Link>

        <Link 
        to="/reactjs-crash-course/"
        style={{
          width: '100%',
          display: 'block',
          textDecoration: 'none',
          margin: '1em auto',
          textAlign: 'center',

        }}
        >
        React.js Crash Course
        </Link>

        <Link 
        to="/backend-development-with-node-express-mongodb/"
        style={{
          width: '100%',
          display: 'block',
          textDecoration: 'none',
          margin: '1em auto',
          textAlign: 'center',

        }}
        >
        Back-end development with Express
        </Link>


        {/* <Link 
        to="/bcn-coding-kids/"
        style={{
          width: '100%',
          display: 'block',
          textDecoration: 'none',
          margin: '1em auto',
          textAlign: 'center',

        }}
        >
        Coding for kids
        </Link> */}



        <p className='navSeparator'><strong>Online short courses&nbsp;↓</strong></p>



        <Link 
        to="/online-short-courses/responsive-web-design-with-css3-online/"
        style={{
          width: '100%',
          display: 'block',
          textDecoration: 'none',
          margin: '1em auto',
          textAlign: 'center',

        }}
        >
        Responsive web design with HTML5/CSS3 
        </Link>

        <Link 
        to="/online-short-courses/programming-with-javascript-online-course/"
        style={{
          width: '100%',
          display: 'block',
          textDecoration: 'none',
          margin: '1em auto',
          textAlign: 'center',

        }}
        >
        Programming with JavaScript Online Course
        </Link>

        <Link 
        to="/online-short-courses/react-crash-course-online/"
        style={{
          width: '100%',
          display: 'block',
          textDecoration: 'none',
          margin: '1em auto',
          textAlign: 'center',

        }}
        >
        React.js Online Crash Course
        </Link>

        <Link 
        to="/online-short-courses/backend-development-with-node-express-mongodb-online/"
        style={{
          width: '100%',
          display: 'block',
          textDecoration: 'none',
          margin: '1em auto',
          textAlign: 'center',

        }}
        >
        Back-end development with Express Online course
        </Link>

        <Link 
        to="/programming-with-python/"
        style={{
          width: '100%',
          display: 'block',
          textDecoration: 'none',
          margin: '1em auto',
          textAlign: 'center',

        }}
        >
        Programming with Python
        </Link>



{/*      <p className='navSeparator'><strong>Kids and corporate&nbsp;↓</strong></p>

      <Link 
      to="/corporate-training/"
      style={{
        width: '100%',
        display: 'block',
        color: '#ddd',
        textDecoration: 'none',
        margin: '1em auto',
        textAlign: 'center',

      }}
      >
      Corporate training
      </Link>

      <Link 
      to="/bcn-coding-kids/bcs-young-coders-summer-camp/"
      style={{
        width: '100%',
        display: 'block',
        color: '#ddd',
        textDecoration: 'none',
        margin: '1em auto',
        textAlign: 'center',
        paddingBottom:'2em',
      }}
      >
      BCS Young Coders
      </Link>*/}

        </div>
        ) : null}
</div>



<div style={{
  position: 'relative',
}}>
<Link style={{
  textDecoration: 'none',
  marginRight: '1em',
  whiteSpace: 'nowrap'
}}
to="#"
onClick={this.showMenuForCompanies}>For companies ↓</Link>
{this.state.showMenuForCompanies ? (
  <div
  style={{
          // figure out best centering for this div
    width: '16em',
    left: '-4em',
    top: '1.5em',
    position: 'absolute',
    background: 'rgb(0,0,0,80%)',
    padding: '1em',
    overflow: 'scroll',
          // height: '100vh',
  }}
  ref={element => {
    this.dropdownMenuForCompanies = element;
  }}
  >


  <Link 
  to="/corporate-training/"
  style={{
    textDecoration: 'none',
    marginRight: '1em',
    whiteSpace: 'nowrap'
  }}
  >
  Training and development
  </Link>

  <Link 
  to="/job-offer/"
  style={{
    width: '100%',
    display: 'block',
    textDecoration: 'none',
    margin: '1em auto 0 auto',
    textAlign: 'center'
  }}
  >
  Hire graduates
  </Link>
  </div>
  ) : null}
</div>

<div style={{
  position: 'relative',

}}>
<Link style={{
  textDecoration: 'none',
  whiteSpace: 'nowrap'
}}
to="#"
onClick={this.showMenuSchool}>School ↓</Link>
{this.state.showMenuSchool ? (
  <div
  style={{
          // figure out best centering for this div
    width: '16em',
    right: '0',
    top:'1.5em',
    position: 'absolute',
    background: 'rgb(0,0,0,80%)',
    padding: '1em',
  }}
  ref={element => {
    this.dropdownMenuSchool = element;
  }}
  >
  <Link 
  to="/about-us/school/"
  style={{
    width: '100%',
    display: 'block',
    textDecoration: 'none',
    margin: '1em auto 0 auto',
    textAlign: 'center'
  }}
  >
  About us
  </Link>

  <Link 
  to="/about-us/testimonials/"
  style={{
    width: '100%',
    display: 'block',
    textDecoration: 'none',
    margin: '1em auto 0 auto',
    textAlign: 'center',

  }}
  >
  Testimonials
  </Link>
  <Link 
  to="/blog/"
  style={{
    width: '100%',
    display: 'block',
    textDecoration: 'none',
    margin: '1em auto 0 auto',
    textAlign: 'center',

  }}
  >
  Blog
  </Link>
  <Link 
  to="/lab/"
  style={{
    width: '100%',
    display: 'block',
    textDecoration: 'none',
    margin: '1em auto 0 auto',
    textAlign: 'center',

  }}
  >
  BCS Lab
  </Link>
  <Link 
  to="/contacts/"
  style={{
    width: '100%',
    display: 'block',
    textDecoration: 'none',
    margin: '1em auto 0 auto',
    textAlign: 'center',

  }}
  >
  Contact us
  </Link>

  </div>
  ) : null}
</div>
</div>
);
}
}

export default Navbar
