import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import Header from '../components/Header'
import Footer from '../components/Footer'
import './index.css'
import '../pages/pages.css'
import favicon from '../pics/favicon.ico';
import {Link} from 'gatsby'

import bckgrnd1 from "../pics/campus/barcelona_code_school_campus_.jpg"
import bckgrnd2 from "../pics/campus/barcelona_code_school_campus_-2.jpg"
import bckgrnd3 from "../pics/campus/barcelona_code_school_campus_-4.jpg"
import bckgrnd4 from "../pics/campus/barcelona_code_school_campus_-6.jpg"
import bckgrnd5 from "../pics/campus/barcelona_code_school_campus_-9.jpg"
import uxBackground from "../pics/campus/ux_students_barcelona_code_school.jpg"
import corporate from "../pics/corporate-training_barcelona-code-school-jikken-kobo.png"
import jsBootcamp from "../pics/campus/coding_bootcamp.jpeg"
// import jsOnline from "../pics/campus/2.jpg"
import jsOnline from "../pics/campus/javascript_fullstack_online_bootcamp.jpg"
import screenCode from "../pics/campus/Bootcamp-1.jpg"
import codingHands from "../pics/campus/js_bootcamp.jpg"
import codingKids from "../pics/campus/bcs_coding_kids.jpg"
import bootcampLuke from '../pics/campus/javascript_fullstack_bootcamp-13.jpg'
import coders from "../pics/campus/web_JS6.jpg"
// import graduates from "../pics/campus/graduates.jpg"
import graduates from "../pics/campus/feedback.jpg"
import gridWorkshop from '../pics/campus/32214925_1015375681954514_8316080609554333696_n.jpg'
import closeIcon from '../pics/icons/xmark-solid.svg'



// class Index extends React.Component {
const Index =(props)=>{
  const styles={
    float:{
      background: 'linear-gradient(90deg, rgb(76 144 175) 0%, rgb(239 251 79) 100%)',
      padding:'1.2em',
      width:'100%',
      display:'flex',
      justifyContent:'space-around',
      alignItems:'center'
    },
    marquee:{
      color: '#ddd',
      textDecoration: 'none',
      marginRight: '7em',
    },
    marqueeHeading: {
      margin:0,
      textDecoration:'underline',
      color:'#1a1a1a',
      wordBreak:'break-all'
    }
  }
  const [floatOpen,setFloatOpen]=useState(false)
  useEffect(()=>{
    let floatPan = JSON.parse(sessionStorage.getItem('uaSpecial'))
    floatPan===false ? setFloatOpen(false):setFloatOpen(true)
  },[])


  let backgrounds = [
    bckgrnd1,bckgrnd2,bckgrnd3,bckgrnd4,bckgrnd5
    ]

  let [backgroundImage, setbackgroundImage]=useState('')

  useEffect(()=>{
    props?.path.includes('ux-design-bootcamp-in-barcelona-code-school') ? 
    setbackgroundImage(uxBackground) : props?.path.includes('corporate-training') ? 
    setbackgroundImage(corporate): props?.path.includes('javascript-full-stack-bootcamp') ? 
    setbackgroundImage(jsBootcamp) : props?.path.includes('javascript-full-stack-online-bootcamp') ? 
    setbackgroundImage(jsOnline) : props?.path.includes('css3') ?
    setbackgroundImage(gridWorkshop): props?.path.includes('backend') ?
    setbackgroundImage(codingKids) : props?.path.includes('programming') ?
    setbackgroundImage(screenCode) : props?.path.includes('react') ?
    setbackgroundImage(coders) : props?.path.includes('job-offer') ?
    setbackgroundImage(graduates) : setbackgroundImage(backgrounds[Math.floor(Math.random() * (backgrounds.length-1 - 0) + 0)])
  },[props?.path])

    // setInterval(function () {
    //   setbackgroundImage(backgrounds[Math.floor(Math.random() * (backgrounds.length-1 - 0) + 0)])
    // }, 30000)

  return (
    <div className='backgroundWrapper' style={{ 
        // 'backgroundImage':`url(${backgroundImage})`,
        // 'backgroundSize': 'cover',
        // 'backgroundRepeat': 'no-repeat',
        // 'backgroundPosition': 'center',
        // 'backgroundAttachment': 'fixed'
    }}>

    <img id='backgroundPLaceholder' src={backgroundImage} style={{
        // 'display':'none',
      'position':'fixed',
        // 'top':'calc(50% - 50vh)',
        // top:0,
        // 'bottom':0,
      'zIndex':-1,
      'minWidth':'100vw',
      'minHeight':'100vh',
        // 'maxWidth':'100vmax',
        // 'maxHeight':'100vmax',
      'objectFit':'cover',
      'objectPosition':'center',
      'overflow':'hidden',
      'filter':'opacity(0.5)',
    }}/>


    <Helmet
    link={[
    {
      rel: "icon",
      type: "image/x-icon",
      href: `${favicon}`
    }
    ]}
    />
      {/*<div className="snowflakes" aria-hidden="true">
      <div className="snowflake">
      ❅
      </div>
      <div className="snowflake">
      ❆
      </div>
      <div className="snowflake">
      ❅
      </div>
      <div className="snowflake">
      ❆
      </div>
      <div className="snowflake">
      ❅
      </div>
      <div className="snowflake">
      ❆
      </div>
      <div className="snowflake">
      ❅
      </div>
      <div className="snowflake">
      ❆
      </div>
      <div className="snowflake">
      ❅
      </div>
      <div className="snowflake">
      ❆
      </div>
      <div className="snowflake">
      ❅
      </div>
      <div className="snowflake">
      ❆
      </div>
    </div>*/}

    {/* floating top marquee */}
    {/* close marquee button */}
    {/*<span onClick={()=>{sessionStorage.setItem('uaSpecial',false);setFloatOpen(false)}}><img src={closeIcon} style={{'width':'1em','verticalAlign': 'middle','margin':'0'}}/> close</span>*/}
{/*    { 
      floatOpen&& <div className='floatPanel' style={styles.float}>
      <Link style={{
        color: '#ddd',
        textDecoration: 'none',
        marginRight: '1em',
      }}
      to="/specials/forced-migrants-refugees-special-scholarship">
      <h3 style={{'margin':'0','textDecoration':'underline','color':'black'}}>Special scholarship for forced migrants</h3>
      </Link>
      <span onClick={()=>{sessionStorage.setItem('uaSpecial',false);setFloatOpen(false)}}><img src={closeIcon} style={{'width':'1em','verticalAlign': 'middle','margin':'0'}}/> close</span>
      </div>
    }*/}

    <section>


    <div className="marquee marquee--hover-pause">

    <div className="marquee__content">

    <div>
    <Link style={styles.marquee}
    to="/machine-learning-and-ai-engineering-bootcamp/">
    <h3 style={styles.marqueeHeading}>Deep Learning & AI bootcamp</h3>
    </Link>
    </div>

    <div>
    <Link style={styles.marquee}
    to="/game-development-bootcamp/">
    <h3 style={styles.marqueeHeading}>On Fire: Game Development bootcamp</h3>
    </Link>
    </div>


    {/* <div>
    <Link style={styles.marquee}
    to="/specials/forced-migrants-refugees-special-scholarship">
    <h3 style={styles.marqueeHeading}>Special scholarship for the forced migrants</h3>
    </Link>
    </div> */}

    <div>
    <Link style={styles.marquee}
    to="/ux-design-online-mentored-bootcamp/">
    <h3 style={styles.marqueeHeading}>New Bootcamp: UX/UI Design Online</h3>
    </Link>
    </div>

    <div>
    <Link style={styles.marquee}
    to="/barcelona-code-school/javascript-full-stack-bootcamp#cohorts">
    <h3 style={styles.marqueeHeading}>Starting next: JavaScript Full-Stack Bootcamp</h3>
    </Link>
    </div>

    </div>

    <div className="marquee__content">

    <div>
    <Link style={styles.marquee}
    to="/machine-learning-and-ai-engineering-bootcamp/">
    <h3 style={styles.marqueeHeading}>Deep Learning & AI bootcamp</h3>
    </Link>
    </div>

    <div>
    <Link style={styles.marquee}
    to="/game-development-bootcamp/">
    <h3 style={styles.marqueeHeading}>On Fire: Game Development bootcamp</h3>
    </Link>
    </div>


    {/* <div>
    <Link style={styles.marquee}
    to="/specials/forced-migrants-refugees-special-scholarship">
    <h3 style={styles.marqueeHeading}>Special scholarship for the forced migrants</h3>
    </Link>
    </div> */}

    <div>
    <Link style={styles.marquee}
    to="/ux-design-online-mentored-bootcamp/">
    <h3 style={styles.marqueeHeading}>New Bootcamp: UX/UI Design Online</h3>
    </Link>
    </div>

    <div>
    <Link style={styles.marquee}
    to="/barcelona-code-school/javascript-full-stack-bootcamp#cohorts">
    <h3 style={styles.marqueeHeading}>Starting next: JavaScript Full-Stack Bootcamp</h3>
    </Link>
    </div>


    </div>

    </div>
    </section>


    <Header/>
    <div 
    className='wrapper'
    style={{
      // 'margin': '0 10%',
      // 'padding': '0',
      // 'maxWidth':'1200px'
        // maxWidth: 960,
        // padding: '0px 1.0875rem 1.45rem',
    }}>
    {props.children}
    </div>
    <Footer/>


    </div>
    )
  // }

}

export default Index
