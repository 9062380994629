import React from 'react'
import { Link } from 'gatsby'
import Navbar from '../navbar_dd'
import NavbarHam from '../navbar'
import ReactGA from "react-ga4";
import Consent from '../Consent'
ReactGA.initialize([
  {
    trackingId: "G-48ENESP49X",
    gaOptions: {
      name: 'main tracker'
    }
  }, {
    trackingId: "AW-856512071",
    gaOptions: {
      name: 'ads tracker'
    }
  }, {
    trackingId: "UA-66109172-1",
    gaOptions: {
      name: 'ads tracker'
    }
  }
]);
ReactGA.send("pageview")


// import ReactGA from 'react-ga';
// ReactGA.initialize(
//   [
//     {
//       trackingId: 'G-48ENESP49X',
//       gaOptions: {
//         name: 'main tracker'
//       }
//     },
//     {
//       trackingId: 'AW-856512071',
//       gaOptions: { name: 'ads tracker' }
//     }
//   ],
//   { debug: false, alwaysSendToDefaultTracker: false }
// );
// ReactGA.initialize('UA-66109172-1')  
// ReactGA.initialize('G-48ENESP49X')  
// ReactGA.initialize() 
// ReactGA.pageview(window.location.pathname + window.location.search);
import Helmet from 'react-helmet'


import './header.css'
import FlipBoard from '../FlipBoard.js'

// const windowGlobal = typeof window !== 'undefined' && window
// alert(windowGlobal.innerWidth)

class Header extends React.Component {

  // componentDidMount() {
  //   ReactGA.pageview(window.location.pathname)
  // }

  componentWillReceiveProps() {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }

  componentDidMount() {
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('consent', 'default', {
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'ad_storage': 'denied',
      'analytics_storage': 'denied',
      'wait_for_update': 500,
    });
    gtag('js', new Date());
    gtag('config', 'AW-856512071');
    gtag('config', 'G-48ENESP49X');
    gtag('config', 'UA-66109172-1');
  }

  allConsentGranted() {
    gtag('consent', 'update', {
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'ad_storage': 'granted',
      'analytics_storage': 'granted'
    });
  }

  render() {
    const slogans = [
      'Just coding, no nonsense / Est. 2015',
      'Upgrade yourself / Est. 2015',
      'Make your mom proud / Est. 2015',
      'JavaScript made me do it / Est. 2015',
      'Code is art / Est. 2015',
      'We pray to code / Est. 2015',
    ]
    return <span className="headerSpan">

      <Helmet
        script={[
          // {"src":"https://www.googletagmanager.com/gtag/js?id=AW-856512071"}, 
          // {"src":"https://www.googletagmanager.com/gtag/js?id=G-48ENESP49X"}
        ]}
      />

      <Navbar className='navbarDesktop' />
      <NavbarHam className='navbarHam' />

      <div className="transparentContainer" id='BCSname' style={{ background: 'rgb(255,255,255,80%)', width: '80%', maxWidth: '1600px' }}><h1 style={{ textAlign: 'left', fontSize: '3em' }} className="bcs"><Link to="/" >
        <FlipBoard text={'Barcelona Code School'} /></Link></h1>
        <h4>{slogans[Math.floor(Math.random() * (slogans.length - 0) + 0)]}</h4>
      </div>
      <Consent allConsentGranted={this.allConsentGranted} />
    </span>
  }
}


export default Header
